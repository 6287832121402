export default {
  path: './answer-packages',
  name: '答卷包管理',
  routes: [
    {
      path: './',
      name: '考试列表',
      redirect: './exam-list',
    },
    {
      path: './exam-list',
      component: './marking-management/answer-packages',
    },
    {
      path: './:examId/list',
      name: '答卷包列表',
      component: './marking-management/answer-packages/components/ShowAnswerPackagesList',
    },
  ],
};
