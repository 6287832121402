export const modalFormLayout = {
  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  },
  noLabelFormLayout: {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 10, offset: 5 },
    },
  },
};

export const middlePageFormLayout = {
  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
      md: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
      md: { span: 12 },
    },
  },

  noLabelFormLayout: {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 10, offset: 6 },
      md: { span: 10, offset: 6 },
    },
  },
};

export const pageFormLayout = {
  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
      md: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
      md: { span: 10 },
    },
  },

  noLabelFormLayout: {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 10, offset: 7 },
      md: { span: 10, offset: 6 },
    },
  },
};
