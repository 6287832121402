// 菜单数据转换为树形格式
export const list2tree = (list: [], { rootId = 0 }: { rootId: number }) => {
  const getTreeData = (pid: number, data: any[]): any =>
    data
      .filter((item) => item.pid === pid)
      .map((item) => {
        const children = getTreeData(item.id, data);
        return children.length > 0 ? { ...item, children } : { ...item };
      });
  return getTreeData(rootId, list);
};

// 获取 id 的 选择项 和 半选项
// NOTE: 当前时间复杂度较高 遇到性能问题 请优化一下
export const getCheckedHalfItems = (ids: string[], data: any[]) => {
  const checked: string[] = [];
  const half: string[] = [];
  ids.forEach((id) => {
    if (!data.some((item) => item.pid === id)) {
      checked.push(id);
    } else {
      const cids = getAllChildrenId(id, data);
      if (cids.every((i) => ids.includes(i))) {
        checked.push(id);
      } else {
        half.push(id);
      }
    }
  });

  return { checked, half };

  // NOTE: 可 memo 优化
  function getAllChildrenId(id: string, allData: any[]): string[] {
    const cid = data.filter((item) => item.pid === id);
    if (cid.length === 0) return [];

    return [...cid, ...cid.map((c: string) => getAllChildrenId(c, allData))];
  }
};

// 生成cascader级联选择器需要的数据，并且排除掉最底层没有子级的数据
export const list2CascaderWithoutMostChildren = (list: [], { rootId = 0 }: { rootId: number }) => {
  const getTreeData = (pid: number, data: any[]): any =>
    data
      .filter((item) => item.pid === pid)
      .map((item) => {
        const children = getTreeData(item.id, data);
        // 最底层的item设置一个识别位isLast
        if (children.length > 0) {
          const childrenList: { isLast: any }[] = [];
          children.forEach((child: { isLast: any }) => {
            if (!child?.isLast) {
              childrenList.push(child);
            }
          });
          return childrenList.length > 0 ? { ...item, children: childrenList } : { ...item };
        }
        return { ...item, isLast: true };
      });
  return getTreeData(rootId, list);
};
