import institutions from './institutions';
import menus from './menus';
import roles from './roles';
import schools from './schools';
import users from './users';

export default {
  path: '/manager-center',
  icon: 'UnorderedListOutlined',
  routes: [
    institutions,
    menus,
    roles,
    users,
    schools,
    {
      path: './',
      redirect: './menus',
    },
  ],
};
