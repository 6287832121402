import type { OnionMiddleware, ResponseInterceptor } from 'umi-request';
import umiRequest from 'umi-request';
import { APP_TOKEN, REFRESH_TOKEN } from '@/constants/localStorage';
import cookie from 'js-cookie';
import { parse, stringify } from 'qs';
import { retryRequest } from './common';
import { RefreshTokenSingle } from './refreshTokenUtil';

export const { setCookieToken, getCookieToken, removeCookieToken, getCookieRefreshToken } = (() => {
  let localToken: undefined | string;
  let localRefreshToken: undefined | string;
  function setToken(token: string, refreshToken: string) {
    // const in30Minutes = 1 / 48;
    // const inOneMinutes= 1/(48*30);
    // const inTwoHour = 1 / 12;
    cookie.set(APP_TOKEN, token, { expires: 10 });
    cookie.set(REFRESH_TOKEN, refreshToken, { expires: 300 });
    localToken = token;
    localRefreshToken = refreshToken;
  }
  function getToken() {
    if (localToken === undefined) localToken = cookie.get(APP_TOKEN);
    return localToken;
  }
  function getRefreshToken() {
    if (localRefreshToken === undefined) localRefreshToken = cookie.get(REFRESH_TOKEN);
    return localRefreshToken;
  }
  function removeToken() {
    cookie.remove(APP_TOKEN);
    cookie.remove(REFRESH_TOKEN);
    localToken = undefined;
    localRefreshToken = undefined;
  }
  return {
    setCookieToken: setToken,
    getCookieToken: getToken,
    removeCookieToken: removeToken,
    getCookieRefreshToken: getRefreshToken,
  };
})();

export const requestMiddleware: OnionMiddleware = async (ctx, next) => {
  const token = getCookieToken();
  ctx.req.url = `${process.env.APP_API_URL}${ctx.req.url}`;
  if (token) {
    ctx.req.options.headers = {
      ...ctx.req.options.headers,
      authorization: `Bearer ${token}`,
    };
  }
  await next();
  ctx.res = adapter(ctx.res);
  function adapter(res: any) {
    if (res?.links && res?.meta) {
      if (Array.isArray(res?.data)) {
        return {
          items: res?.data,
          meta: res?.meta,
        };
      }
    }
    return ctx.res.data;
  }
};

/**
 * 请求后拦截器,对于数据进行校验，是否已经进行过token refresh了
 */
export const accessInterceptors: ResponseInterceptor = async (response, options) => {
  if (
    response.status === 401 &&
    response.url !== `${process.env.APP_API_URL}/backend/auth/token/refresh`
  ) {
    (options as any).$retry = true;
    const oldRefreshToken = getCookieRefreshToken();
    if (oldRefreshToken) {
      try {
        const refreshUtil = RefreshTokenSingle.getInstance();
        await refreshUtil.refreshToken();
        // 重新进行构造
        const { params, headers, method } = options;
        // 发起的请求一定是https的
        const url = response.url.replace(/^https:\/\/[^/]+/, '').replace(/^http:\/\/[^/]+/, '');
        // 重新发起请求
        const retryData = await retryRequest(url, headers, method, params);
        // 需要手动模拟的将response关键数据返回，success 返回为true
        return {
          success: true,
          data: retryData,
          message: 'success',
        } as any;
      } catch (err) {
        console.log(err.message);
      }
    }
  }
  return response;
};

export const OSSRequest = async (urlRaw: string, arg?: any) => {
  const replace = (url: string) => url.replace(/^http:\/\//, 'https://');
  const url = replace(urlRaw);
  try {
    // TODO: 验证下
    if (url.includes('download?fileid')) {
      const [baseUrl, params = ''] = url.split('?');
      const paramsObj = { ...parse(params), type: 1 };
      const downloadData = (await umiRequest<string>(
        `${baseUrl}?${stringify(paramsObj)}`,
        arg,
      )) as any;
      const resultUrl = replace(downloadData);
      const result = await umiRequest(resultUrl, arg);
      return result as any;
    }
    const result = await umiRequest(url, arg);
    return result as any;
  } catch (error) {
    const result = await umiRequest(url, arg);
    return result as any;
  }
};

export const download = (url: string, ...args: any) => {
  return umiRequest(process.env.FILE_URL + url, ...args);
};
