export default {
  path: './:exam_id/rounds',
  name: '场次管理',
  routes: [
    {
      path: './',
      name: '场次管理',
      redirect: './list',
    },
    {
      path: './list',
      component: './exams/rounds/list',
    },
    {
      path: './:roundId/round-students',
      name: '场次考生名单',
      component: './exams/rounds/round-students',
    },
    {
      path: './:roundId/set-papers',
      routes: [
        {
          path: './',
          name: '场次试卷管理',
          redirect: './list',
        },
        {
          path: './list',
          component: './exams/rounds/set-papers/list',
        },
        {
          path: './create',
          name: '场次试卷添加',
          component: './exams/rounds/set-papers/create',
        },
        {
          path: './:paperId/edit',
          name: '场次试卷编辑',
          component: './exams/rounds/set-papers/edit',
        },
      ],
    },
  ],
};
