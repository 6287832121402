export default {
  path: './:exam_id/marking-users',
  name: '阅卷管理',
  routes: [
    {
      path: './',
      redirect: './list',
    },
    {
      path: './list',
      component: './exams/marking-users',
    },
    {
      path: './paper/:paper_id',
      name: '阅卷设置详情',
      component: './exams/marking-users/paper-info',
    },
  ],
};
