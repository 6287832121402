export default {
  path: './:examId/three-review',
  name: '三改卷管理',
  routes: [
    {
      path: './',
      redirect: './list',
    },
    {
      path: './list',
      component: './marking-management/marking-review/three-review',
    },
    {
      path: './:reviewId/review',
      name: '复核',
      component: './marking-management/marking-review/three-review/components/ThreeReviewForm',
    },
  ],
};
