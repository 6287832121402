import { useState } from 'react';
import { queryRegionMap } from '@/utils/common';
import { history } from 'umi';

export default function useRegionMapModel() {
  const [regionMap, setRegionMap] = useState([]);

  const getRegionMap = async () => {
    if (
      regionMap.length < 1 &&
      history.location.pathname !== '/access/institution/login' &&
      history.location.pathname !== '/access/school/login' &&
      history.location.pathname !== '/access/starnet-system/login'
    ) {
      const data = await queryRegionMap();
      setRegionMap(data);
    }
  };

  return { regionMap, getRegionMap };
}
