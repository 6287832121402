import marking from './marking';
import rounds from './rounds';
import paperPackage from './paperPackage';
import roomsSummary from './rooms-summary';
import performance from './performance';

export default {
  path: '/exams',
  name: '考试管理',
  routes: [
    rounds,
    marking,
    paperPackage,
    roomsSummary,
    performance,
    {
      path: './',
      name: '考试管理',
      redirect: './list',
    },
    {
      path: './list',
      component: './exams/list',
    },
    {
      path: './:exam_id/review-users/list',
      name: '复核老师',
      component: './exams/review-users/list',
    },
    {
      path: './:exam_id/rooms/list',
      name: '考场设置',
      component: './exams/rooms/list',
    },
    {
      path: './:exam_id/students/list',
      name: '考生报考情况',
      component: './exams/students',
    },
    {
      path: './:exam_id/arrange-package/',
      name: '考场每日编排数据包',
      component: './exams/daily-arrange-package/index',
    },
    {
      path: './:exam_id/exempt-students/',
      name: '免考考生',
      component: './exams/exempt-students',
    },
    {
      path: './:exam_id/cheat-students/',
      name: '作弊考生管理',
      component: './exams/cheat-students',
    },
    {
      path: './:exam_id/exam-stat/',
      name: '数据统计指标',
      component: './exams/exam-stat',
    },
  ],
};
