import { initPermissions } from './authorize-component';

export interface IRunConfig {
  permission?: string[];
}

export function run(config?: IRunConfig) {
  // 初始化权限系统
  initPermissions(config?.permission || []);
}
