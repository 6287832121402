import commons from './commons';
import examSites from './exam-sites';
import home from './home';
import manage from './manage';
import proposition from './proposition';
import exams from './exams';
import marking from './marking';
import opr from './opr';

export default [manage, home, examSites, proposition, exams, marking, opr, ...commons];
