export default {
  path: './:exam_id/performance',
  name: '成绩查看与发布',
  routes: [
    {
      path: './',
      component: './exams/performance',
    },
    {
      path: './:stu_id',
      name: '阅卷详情',
      component: './exams/performance/components/PaperAnswerInfo',
    },
  ],
};
