export default {
  path: './:exam_id/paper-package',
  name: '加密试卷包管理',
  routes: [
    {
      path: './',
      // component: './exams/paper-package',
      redirect: './list',
    },
    {
      path: './list',
      component: './exams/paper-package',
    },
    {
      path: './:paperId/edit-paper',
      name: '试卷编辑',
      component: './exams/rounds/set-papers/edit',
    },
  ],
};
