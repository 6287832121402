export default {
  path: './qbank-manage',
  name: '题库管理',
  routes: [
    {
      path: './',
      name: '题库管理',
      component: './proposition/qbank-manage/index',
    },
    {
      path: './create',
      name: '新建题目',
      component: './proposition/qbank-manage/create',
    },
    {
      path: './edit/:id',
      name: '编辑题目',
      component: './proposition/qbank-manage/edit',
    },
  ],
};
