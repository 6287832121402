export default {
  path: './institutions',
  name: '教育厅/局管理',
  routes: [
    {
      path: './',
      name: '教育厅/局管理',
      component: './manager-center/institutions/index',
    },
    {
      path: './user-list/:insId',
      name: '教育厅/局用户列表',
      component: './manager-center/institutions/user-list',
    },
  ],
};
