import { request } from 'umi';

// 考试列表
export const queryExamsList = (params) => request('/backend/exams', { params });

// 创建考试
export const createExams = (params) =>
  request('/backend/exam/create', {
    method: 'POST',
    data: params,
  });

// 考试详情
export const detailsExams = ({ id }) => request(`/backend/exam/${id}`);

// 编辑考试
export const editExams = (params, id) =>
  request(`/backend/exam/${id}/edit`, {
    method: 'POST',
    data: params,
  });
// 复核老师删除
export const deleteExams = (id) =>
  request(`/backend/exam/${id}/delete`, {
    method: 'DELETE',
  });

// 复核老师列表
export const queryReviewUsersList = (params) => request('/backend/exam-review-users', { params });

// 复核老师删除
export const deleteReviewUsers = (id) =>
  request(`/backend/exam-review-user/${id}/delete`, {
    method: 'DELETE',
  });

// 获取所有下属教育厅/局/学校的用户列表
export const queryAllUsersList = (params) => request('/backend/child-users', { params });

// 添加一个复核老师
export const createExamReview = (params) =>
  request('/backend/exam-review-user/create', {
    method: 'POST',
    data: params,
  });

// 批量添加复核老师
export const createBatchExamReview = (params) =>
  request('/backend/exam-review-user/batch-create', {
    method: 'POST',
    data: params,
  });

// 获取考试所用的考场列表
export const queryExamRooms = (params = {}) => {
  params.include = 'site.school';
  return request(`/backend/exam/${params.id}/rooms`, {
    method: 'GET',
    params,
  });
};

// 批量添加考试所用的考场
export const createBatchExamRooms = (params) =>
  request('/backend/exam-use-room/batch-add', {
    method: 'POST',
    data: params,
  });

// 考场删除
export const deleteExamRooms = (params) =>
  request(`/backend/exam-use-room/batch-delete`, {
    method: 'DELETE',
    data: params,
  });

// 考场删除
export const deleteExamRoom = (id) =>
  request(`/backend/exam-use-room/${id}/delete`, {
    method: 'DELETE',
  });

// 场次列表
export const queryRoundsList = ({ id, ...params }) =>
  request(`/backend/exam/${id}/rounds`, { params });

// 场次详情
export const queryRoundDetails = ({ id, ...params }) =>
  request(`/backend/exam-round/${id}`, { params });

// 创建考试场次
export const createExamRounds = (params) =>
  request('/backend/exam-round/create', {
    method: 'POST',
    data: params,
  });

// 编辑考试场次
export const editExamRounds = (params, id) =>
  request(`/backend/exam-round/${id}/edit`, {
    method: 'POST',
    data: params,
  });

// 删除考试场次
export const deleteExamRounds = (id) =>
  request(`/backend/exam-round/${id}/delete`, {
    method: 'DELETE',
  });

// 获取场次所用的试卷
export const queryExamRoomPapers = (id, params) => {
  return request(`/backend/exam-round/${id}/papers`, {
    method: 'GET',
    params,
  });
};

// 设置考试场次所用试卷
export const createExamRoundSetPaper = (params = {}, id) =>
  request(`/backend/exam-round/${id}/set-paper`, {
    method: 'POST',
    data: params,
  });

export const createPaperBaseInfo = (params) =>
  request('/backend/production-paper/create', {
    data: params,
    method: 'POST',
  });

// 设置试卷结构
export const setPaperStruct = (id, params) =>
  request(`/backend/production-paper/${id}/set-structure`, {
    method: 'POST',
    data: params,
  });

// 编辑试卷基础信息
export const editPaperBaseInfo = (params, id) =>
  request(`/backend/production-paper/${id}/edit`, {
    data: params,
    method: 'POST',
  });

// 获取试卷基本信息
export const getPaperBaseInfo = (id) => request(`/backend/production-paper/${id}`);

// 获取试卷结构信息
export const getPaperStructureInfo = (id) => request(`/backend/production-paper/${id}/structure`);

// 删除考试场次所用试卷
export const deleteRoundPaper = (id) =>
  request(`/backend/exam-round-paper/${id}/delete`, {
    method: 'DELETE',
  });

// 获取考试学生基本信息
export const getRoundStudents = (params = {}) => {
  params.include = 'room,site,arrange.student.school';
  return request(`/backend/exam-round/${params.id}/seats`, {
    method: 'GET',
    params,
  });
};

// 获取所有参加考试的考点
export const queryAllExamSite = (id) => request(`/backend/exam/${id}/sites`);

// 获取所有考场名称
export const queryAllNameExamRoom = (params = {}) =>
  request('/backend/exam-room/all-names', { params });

// 获取考试考生
export const queryExamStudents = (params = {}) => {
  params.include = 'arrange';
  return request(`/backend/exam-students`, {
    method: 'GET',
    params,
  });
};

// 单个设置考试考生座位
export const arrangeSeat = (params = {}) =>
  request(`/backend/exam-student/set-seat-arrange`, {
    method: 'POST',
    params,
  });

// 取消考试考生座位
export const deleteArrangeSeat = (params = {}) =>
  request(`/backend/exam-student/cancel-seat-arrange`, {
    method: 'POST',
    params,
  });

// 获取所有参加考试的学校信息
export const queryAllExamSchools = (id) => request(`/backend/exam/${id}/schools`);

// 获取某场考试按日分组的试卷列表
export const queryDailyPaperGroups = (params = {}) =>
  request(`/backend/exam-paper/daily-paper-groups`, {
    method: 'GET',
    params,
  });

// 获取某场考试某日的试卷包
export const queryDailyPaperPackage = (params = {}) =>
  request(`/backend/exam-paper/daily-paper-package`, {
    method: 'GET',
    params,
  });

// 创建某场考试指定日期的试卷包
export const createDailyPaperPackage = (params = {}) =>
  request(`/backend/exam-paper/create-daily-paper-package`, {
    method: 'POST',
    params,
  });

// 获取某场考试某日的试卷包创建任务详情
export const detailsCreateDailyPaperPackage = (params = {}) =>
  request(`/backend/exam-paper/daily-paper-package-create-task/${params.id}`, {
    method: 'GET',
    params,
  });

// 获取某场考试某日的试卷包创建任务列表
export const queryPaperPackageCreateTasks = (params = {}) =>
  request(`/backend/exam-paper/daily-paper-package-create-tasks`, {
    method: 'GET',
    params,
  });

// 获取作弊考生列表
export const queryCheatStudentsList = (params = {}) =>
  request(`/backend/exam-student/cheat-students`, {
    method: 'GET',
    params,
  });

// 设置作弊考生
export const addCheatStudent = (params = {}) =>
  request('/backend/exam-student/set-student-cheat', {
    method: 'POST',
    data: params,
  });

// 移除作弊考生
export const deleteCheatStudent = (params = {}) =>
  request(`/backend/exam-student/cancel-student-cheat`, {
    method: 'POST',
    data: params,
  });

// 作弊考生操作日志
export const logsCheatStudent = (params = {}) => {
  params.include =
    'student.arrange.site,student.arrange.room,student.arrange.seat,student.arrange.round';
  return request(`/backend/exam-student/cheat-student-change-logs`, {
    method: 'GET',
    params,
  });
};
