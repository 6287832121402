import { useState } from 'react';
import { history } from 'umi';
import { getOprCodeMap } from '@/utils/common';

export default function useOprMapModel() {
  const [oprMap, setOprMap] = useState([]);

  const getOprMap = async () => {
    if (
      oprMap.length < 1 &&
      history.location.pathname !== '/access/institution/login' &&
      history.location.pathname !== '/access/school/login' &&
      history.location.pathname !== '/access/starnet-system/login'
    ) {
      const data = await getOprCodeMap();
      setOprMap(data);
    }
  };

  return { oprMap, getOprMap };
}
