import { request } from 'umi';

export const logout = () => {
  return request('/backend/auth/logout', {
    method: 'POST',
  });
};

export const changePassword = (userId, params) => {
  return request(`/backend/user/${userId}/set-password`, {
    method: 'POST',
    params,
  });
};
