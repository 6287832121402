export default {
  path: './schools',
  name: '学校管理',
  routes: [
    {
      path: './',
      name: '学校管理',
      component: './manager-center/schools/index',
    },
    {
      path: './user-list/:schoolId',
      name: '学校用户列表',
      component: './manager-center/schools/user-list',
    },
  ],
};
