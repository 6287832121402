export default {
  path: '/exam-sites',
  name: '考点管理',
  routes: [
    {
      path: './',
      name: '考点管理',
      component: './examination-sites/sites',
    },
    {
      path: './:examSiteId/rooms',
      name: '考场管理',
      component: './examination-sites/rooms',
    },
    {
      path: './:examSiteId/rooms/:roomId/seats',
      name: '座位管理',
      component: './examination-sites/room-seats',
    },
    {
      path: './:examSiteId/rooms/:roomId/invigilators',
      name: '监考主机管理',
      component: './examination-sites/invigilator',
    },
  ],
};
