import { Space } from 'antd';
import React, { useState, useEffect } from 'react';
import { useModel, history } from 'umi';
import avatar from '@/pages/access/layout/avatar.png';
import styles from './index.less';
import logout from '@/pages/access/layout/logout.png';
import password from '@/pages/access/layout/password.png';
import * as services from './service';
import { removeCookieToken } from '@/utils/request';
import ChangePasswordModal from './components/ChangePasswordModal';

export type SiderTheme = 'light' | 'dark';

const GlobalHeaderRight: React.FC = () => {
  const { initialState, setInitialState } = useModel('@@initialState');
  const { getRegionMap } = useModel('regionMap');
  const { getOprMap } = useModel('oprMap');
  const [editPasswordVisible, setEditPasswordVisible] = useState(false);

  useEffect(() => {
    getRegionMap();
    getOprMap();
  }, []);

  if (!initialState || !initialState.settings || !initialState.currentUser) {
    return null;
  }

  const {
    currentUser: { nickname: name, group_relation_name: groupName, group_type },
  } = initialState;

  const { navTheme, layout } = initialState.settings;
  let className = styles.right;

  const typeMap = ['starnet-system', 'institution', 'school'];

  if ((navTheme === 'dark' && layout === 'top') || layout === 'mix') {
    className = `${styles.right}  ${styles.dark}`;
  }

  const onLogOut = async () => {
    // 清除用户信息
    const url = `/access/${typeMap[group_type]}/login`;
    // 退出后回到首页登录页面，判断当前是哪个类型用户登录的，回到对应的类型登录界面
    history.push(url);
    // 发送请求
    await services.logout();

    // 清除token
    removeCookieToken();
    setInitialState({ ...initialState, currentUser: undefined });
  };

  // 打开修改密码的弹窗
  const openEditPasswordModal = () => {
    setEditPasswordVisible(true);
  };

  const handleCloseEditPasswordModal = () => {
    setEditPasswordVisible(false);
  };

  return (
    <Space className={`${className} ${styles.menu}`}>
      {/* 个人头像和名字 */}
      <div className={styles.item}>
        <img alt="头像" src={avatar} style={{ paddingRight: 4 }} />
        <span className={styles.groupname}>{groupName}</span>
        <span className={styles.name}>{name}</span>
      </div>

      {/* 修改密码 */}
      <a className={`${styles['hover-item']} ${styles.item}`} onClick={openEditPasswordModal}>
        <img alt="修改密码" src={password} style={{ paddingRight: 4 }} />
        <span className={styles.name}>修改密码</span>
      </a>

      {/* 退出按钮 */}
      <a className={`${styles['hover-item']} ${styles.item}`} onClick={onLogOut}>
        <img alt="logout" src={logout} style={{ paddingRight: 4 }} />
        <span>退出</span>
      </a>
      {editPasswordVisible && (
        <ChangePasswordModal
          visible={editPasswordVisible}
          onCancel={handleCloseEditPasswordModal}
        />
      )}
    </Space>
  );
};
export default GlobalHeaderRight;
