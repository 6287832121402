import { detailsExams } from './service';

export const namespace = 'exams';

export const effects = {
  getCurrentExam: `${namespace}/getCurrentExam`,
  setCurrentExam: `${namespace}/setCurrentExam`,
};

export default {
  namespace,

  state: {
    exam: {},
  },

  effects: {
    *getCurrentExam({ payload }, { call, put }) {
      const data = yield call(detailsExams, payload);

      yield put({
        type: 'saveExam',
        payload: data,
      });
    },

    *setCurrentExam({ payload }, { put }) {
      yield put({
        type: 'saveExam',
        payload,
      });
    },
  },

  reducers: {
    saveExam(state, action) {
      return {
        ...state,
        exam: action.payload,
      };
    },
  },
};
