export default {
  path: '/opr',
  name: '操作记录审计',
  routes: [
    {
      path: './',
      component: './operation',
    },
  ],
};
