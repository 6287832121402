import ThreeReview from './three-review';
import DifferencesReview from './differences-review';

export default {
  path: './review',
  name: '阅卷复核',
  routes: [
    ThreeReview,
    DifferencesReview,
    {
      path: './',
      redirect: './review-exam-list',
    },
    {
      path: './review-exam-list',
      component: './marking-management/marking-review',
    },
  ],
};
