import { request } from 'umi';

// 考场列表检索
export const queryExamRoom = (params) => request('/backend/exam-rooms', { params });

// 创建考场
export const createExamRoom = (params) =>
  request('/backend/exam-room/create', {
    method: 'POST',
    data: params,
  });

// 编辑考场
export const editExamRoom = (params, id) =>
  request(`/backend/exam-room/${id}/edit`, {
    method: 'POST',
    data: params,
  });

// 删除考场
export const deleteExamRoom = (id) =>
  request(`/backend/exam-room/${id}/delete`, {
    method: 'DELETE',
  });

// 启用考场
export const enableExamRoom = (id) =>
  request(`/backend/exam-room/${id}/enable`, {
    method: 'POST',
  });

// 禁用考场
export const disableExamRoom = (id) =>
  request(`/backend/exam-room/${id}/disable`, {
    method: 'POST',
  });
