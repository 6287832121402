// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 't__plugin-layout__Layout' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/.umi-production/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/manager-center",
        "icon": "UnorderedListOutlined",
        "routes": [
          {
            "path": "/manager-center/institutions",
            "name": "教育厅/局管理",
            "routes": [
              {
                "path": "/manager-center/institutions/",
                "name": "教育厅/局管理",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__manager-center__institutions__index' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/manager-center/institutions/index'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/manager-center/institutions/user-list/:insId",
                "name": "教育厅/局用户列表",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__manager-center__institutions__user-list' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/manager-center/institutions/user-list'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/manager-center/menus",
            "name": "菜单管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__manager-center__menus' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/manager-center/menus'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/manager-center/roles",
            "name": "菜单管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__manager-center__roles' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/manager-center/roles'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/manager-center/users",
            "name": "用户管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__manager-center__users' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/manager-center/users'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/manager-center/schools",
            "name": "学校管理",
            "routes": [
              {
                "path": "/manager-center/schools/",
                "name": "学校管理",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__manager-center__schools__index' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/manager-center/schools/index'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/manager-center/schools/user-list/:schoolId",
                "name": "学校用户列表",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__manager-center__schools__user-list' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/manager-center/schools/user-list'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/manager-center/",
            "redirect": "/manager-center/menus",
            "exact": true
          }
        ]
      },
      {
        "path": "/home",
        "name": "首页",
        "icon": "HomeOutlined",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/home'), loading: LoadingComponent}),
        "permission_name": "home",
        "access": "menu",
        "exact": true
      },
      {
        "path": "/exam-sites",
        "name": "考点管理",
        "routes": [
          {
            "path": "/exam-sites/",
            "name": "考点管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__examination-sites__sites' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/examination-sites/sites'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/exam-sites/:examSiteId/rooms",
            "name": "考场管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__examination-sites__rooms' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/examination-sites/rooms'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/exam-sites/:examSiteId/rooms/:roomId/seats",
            "name": "座位管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__examination-sites__room-seats' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/examination-sites/room-seats'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/exam-sites/:examSiteId/rooms/:roomId/invigilators",
            "name": "监考主机管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__examination-sites__invigilator' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/examination-sites/invigilator'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/proposition",
        "icon": "UnorderedListOutlined",
        "routes": [
          {
            "path": "/proposition/qbank-manage",
            "name": "题库管理",
            "routes": [
              {
                "path": "/proposition/qbank-manage/",
                "name": "题库管理",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__proposition__qbank-manage__index' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/proposition/qbank-manage/index'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/proposition/qbank-manage/create",
                "name": "新建题目",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__proposition__qbank-manage__create' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/proposition/qbank-manage/create'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/proposition/qbank-manage/edit/:id",
                "name": "编辑题目",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__proposition__qbank-manage__edit' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/proposition/qbank-manage/edit'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/proposition/test-paper",
            "name": "试卷管理",
            "routes": [
              {
                "path": "/proposition/test-paper/",
                "name": "试卷管理",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__proposition__test-paper__index' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/proposition/test-paper/index'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/proposition/test-paper/create",
                "name": "新建试卷",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__proposition__test-paper__create' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/proposition/test-paper/create'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/proposition/test-paper/edit/:paperId",
                "name": "编辑试卷",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__proposition__test-paper__edit' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/proposition/test-paper/edit'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/proposition/",
            "redirect": "/proposition/qbank-manage",
            "exact": true
          }
        ]
      },
      {
        "path": "/exams",
        "name": "考试管理",
        "routes": [
          {
            "path": "/exams/:exam_id/rounds",
            "name": "场次管理",
            "routes": [
              {
                "path": "/exams/:exam_id/rounds/",
                "name": "场次管理",
                "redirect": "/exams/:exam_id/rounds/list",
                "exact": true
              },
              {
                "path": "/exams/:exam_id/rounds/list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exams__rounds__list' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/exams/rounds/list'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/exams/:exam_id/rounds/:roundId/round-students",
                "name": "场次考生名单",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exams__rounds__round-students' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/exams/rounds/round-students'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/exams/:exam_id/rounds/:roundId/set-papers",
                "routes": [
                  {
                    "path": "/exams/:exam_id/rounds/:roundId/set-papers/",
                    "name": "场次试卷管理",
                    "redirect": "/exams/:exam_id/rounds/:roundId/set-papers/list",
                    "exact": true
                  },
                  {
                    "path": "/exams/:exam_id/rounds/:roundId/set-papers/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exams__rounds__set-papers__list' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/exams/rounds/set-papers/list'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/exams/:exam_id/rounds/:roundId/set-papers/create",
                    "name": "场次试卷添加",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exams__rounds__set-papers__create' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/exams/rounds/set-papers/create'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/exams/:exam_id/rounds/:roundId/set-papers/:paperId/edit",
                    "name": "场次试卷编辑",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exams__rounds__set-papers__edit' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/exams/rounds/set-papers/edit'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              }
            ]
          },
          {
            "path": "/exams/:exam_id/marking-users",
            "name": "阅卷管理",
            "routes": [
              {
                "path": "/exams/:exam_id/marking-users/",
                "redirect": "/exams/:exam_id/marking-users/list",
                "exact": true
              },
              {
                "path": "/exams/:exam_id/marking-users/list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exams__marking-users' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/exams/marking-users'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/exams/:exam_id/marking-users/paper/:paper_id",
                "name": "阅卷设置详情",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exams__marking-users__paper-info' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/exams/marking-users/paper-info'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/exams/:exam_id/paper-package",
            "name": "加密试卷包管理",
            "routes": [
              {
                "path": "/exams/:exam_id/paper-package/",
                "redirect": "/exams/:exam_id/paper-package/list",
                "exact": true
              },
              {
                "path": "/exams/:exam_id/paper-package/list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exams__paper-package' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/exams/paper-package'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/exams/:exam_id/paper-package/:paperId/edit-paper",
                "name": "试卷编辑",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exams__rounds__set-papers__edit' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/exams/rounds/set-papers/edit'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/exams/:exam_id/rooms-summary/",
            "name": "考场情况",
            "routes": [
              {
                "path": "/exams/:exam_id/rooms-summary/",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exams__rooms-summary' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/exams/rooms-summary'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/exams/:exam_id/rooms-summary/distribute",
                "name": "自动分配",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exams__distribute' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/exams/distribute'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/exams/:exam_id/performance",
            "name": "成绩查看与发布",
            "routes": [
              {
                "path": "/exams/:exam_id/performance/",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exams__performance' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/exams/performance'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/exams/:exam_id/performance/:stu_id",
                "name": "阅卷详情",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exams__performance__components__PaperAnswerInfo' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/exams/performance/components/PaperAnswerInfo'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/exams/",
            "name": "考试管理",
            "redirect": "/exams/list",
            "exact": true
          },
          {
            "path": "/exams/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exams__list' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/exams/list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/exams/:exam_id/review-users/list",
            "name": "复核老师",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exams__review-users__list' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/exams/review-users/list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/exams/:exam_id/rooms/list",
            "name": "考场设置",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exams__rooms__list' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/exams/rooms/list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/exams/:exam_id/students/list",
            "name": "考生报考情况",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exams__students' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/exams/students'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/exams/:exam_id/arrange-package/",
            "name": "考场每日编排数据包",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exams__daily-arrange-package__index' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/exams/daily-arrange-package/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/exams/:exam_id/exempt-students/",
            "name": "免考考生",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exams__exempt-students' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/exams/exempt-students'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/exams/:exam_id/cheat-students/",
            "name": "作弊考生管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exams__cheat-students' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/exams/cheat-students'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/exams/:exam_id/exam-stat/",
            "name": "数据统计指标",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exams__exam-stat' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/exams/exam-stat'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/marking",
        "name": "阅卷管理",
        "routes": [
          {
            "path": "/marking/answer-packages",
            "name": "答卷包管理",
            "routes": [
              {
                "path": "/marking/answer-packages/",
                "name": "考试列表",
                "redirect": "/marking/answer-packages/exam-list",
                "exact": true
              },
              {
                "path": "/marking/answer-packages/exam-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__marking-management__answer-packages' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/marking-management/answer-packages'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/marking/answer-packages/:examId/list",
                "name": "答卷包列表",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__marking-management__answer-packages__components__ShowAnswerPackagesList' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/marking-management/answer-packages/components/ShowAnswerPackagesList'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/marking/review",
            "name": "阅卷复核",
            "routes": [
              {
                "path": "/marking/review/:examId/three-review",
                "name": "三改卷管理",
                "routes": [
                  {
                    "path": "/marking/review/:examId/three-review/",
                    "redirect": "/marking/review/:examId/three-review/list",
                    "exact": true
                  },
                  {
                    "path": "/marking/review/:examId/three-review/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__marking-management__marking-review__three-review' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/marking-management/marking-review/three-review'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/marking/review/:examId/three-review/:reviewId/review",
                    "name": "复核",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__marking-management__marking-review__three-review__components__ThreeReviewForm' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/marking-management/marking-review/three-review/components/ThreeReviewForm'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/marking/review/:examId/differences-review",
                "name": "重大分歧复核管理",
                "routes": [
                  {
                    "path": "/marking/review/:examId/differences-review/",
                    "redirect": "/marking/review/:examId/differences-review/list",
                    "exact": true
                  },
                  {
                    "path": "/marking/review/:examId/differences-review/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__marking-management__marking-review__differences-review' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/marking-management/marking-review/differences-review'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/marking/review/:examId/differences-review/:reviewId/review",
                    "name": "复核",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__marking-management__marking-review__differences-review__components__ReviewForm' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/marking-management/marking-review/differences-review/components/ReviewForm'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/marking/review/",
                "redirect": "/marking/review/review-exam-list",
                "exact": true
              },
              {
                "path": "/marking/review/review-exam-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__marking-management__marking-review' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/marking-management/marking-review'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/marking/marking-paper",
            "name": "评阅试卷",
            "routes": [
              {
                "path": "/marking/marking-paper/",
                "redirect": "/marking/marking-paper/exam-list",
                "exact": true
              },
              {
                "path": "/marking/marking-paper/exam-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__marking-management__marking-paper' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/marking-management/marking-paper'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/marking/marking-paper/:examId/paper",
                "name": "评阅",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__marking-management__marking-paper__components__MarkingPaperForm' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/marking-management/marking-paper/components/MarkingPaperForm'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/marking/",
            "redirect": "/marking/marking-paper",
            "exact": true
          }
        ]
      },
      {
        "path": "/opr",
        "name": "操作记录审计",
        "routes": [
          {
            "path": "/opr/",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/operation'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/",
        "redirect": "/home",
        "exact": true
      },
      {
        "layout": false,
        "path": "/access/:loginType/login",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__access__login' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/access/login'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/home/deploy/.jenkins/workspace/test@2/project/formal-exam/exam-system/code/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
