export default {
  path: './:examId/differences-review',
  name: '重大分歧复核管理',
  routes: [
    {
      path: './',
      redirect: './list',
    },
    {
      path: './list',
      component: './marking-management/marking-review/differences-review',
    },
    {
      path: './:reviewId/review',
      name: '复核',
      component: './marking-management/marking-review/differences-review/components/ReviewForm',
    },
  ],
};
