import React from 'react';
import footerLogo from '@/pages/access/layout/footer_logo.png';
import styles from './index.less';

export default () => (
  <>
    <div className={styles.footer}>
      <img alt="footer logo" src={footerLogo} />
      <span>·音乐考试管理平台</span>
    </div>
  </>
);
