import { useModel } from 'umi';
import { Modal, Input, Button, Form, message } from 'antd';
import React, { useState } from 'react';
import * as service from '../service';
import { modalFormLayout } from '@/utils/forms/layouts';

const { formItemLayout } = modalFormLayout;

const ChangePasswordModal = ({ onCancel, visible }) => {
  const {
    initialState: {
      currentUser: { id: userId },
    },
  } = useModel('@@initialState');

  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const validatePassword = () => ({
    validator(_, value) {
      if (!value || value?.length >= 6) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('密码至少为 6 位'));
    },
  });

  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('两次输入的密码不一致！'));
    },
  });

  const handleFinish = async () => {
    await form.validateFields().then(async (values) => {
      setSubmitting(true);
      await service.changePassword(userId, values);
      message.success('修改密码成功');
      onCancel();
    });
  };

  // 模态框底部按钮
  const footer = [
    <Button key="cancel" disabled={submitting} onClick={onCancel}>
      取消
    </Button>,
    <Button
      key="ok"
      type="primary"
      className="button-color-blue"
      loading={submitting}
      onClick={handleFinish}
    >
      确定
    </Button>,
  ];

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={'修改密码'}
      maskClosable={false}
      footer={footer}
    >
      <Form form={form}>
        <Form.Item
          name="password"
          label="新密码"
          {...formItemLayout}
          rules={[{ required: true, message: '请输入新密码！' }, validatePassword]}
        >
          <Input.Password placeholder="请输入新密码" />
        </Form.Item>
        <Form.Item
          name="repeat_password"
          label="确认新密码"
          {...formItemLayout}
          rules={[{ required: true, message: '两次输入的密码不一致！' }, validateConfirmPassword]}
        >
          <Input.Password placeholder="请输入再次输入新密码" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default ChangePasswordModal;
