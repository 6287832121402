import { request } from 'umi';

export const querySitesList = (params) => request('/backend/exam-sites', { params });

export const queryAllNameInstitutions = () => request('/backend/institution/all-names');

export const queryAllNameSchool = (params) => request('/backend/school/all-names', { params });

export const createExamSite = (params) =>
  request('/backend/exam-site/create', {
    data: params,
    method: 'POST',
  });

export const editExamSite = (params, id) =>
  request(`/backend/exam-site/${id}/edit`, {
    data: params,
    method: 'POST',
  });

export const deleteExamSite = (id) =>
  request(`/backend/exam-site/${id}/delete`, {
    method: 'DELETE',
  });

export const enableExamSite = (id) =>
  request(`/backend/exam-site/${id}/enable`, {
    method: 'POST',
  });

export const disableExamSite = (id) =>
  request(`/backend/exam-site/${id}/disable`, {
    method: 'POST',
  });
