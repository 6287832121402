export const PermissionKey = {
  // 菜单管理
  MENU_MANAGE_CREATE: 'menus.create', // 菜单创建
  MENU_MANAGE_EDIT: 'menus.edit', // 菜单编辑
  MENU_MANAGE_DELETE: 'menus.delete', // 菜单删除

  // 角色管理
  ROLE_MANAGE_CREATE: 'roles.create', // 创建角色
  ROLE_MANAGE_EDIT: 'roles.edit', // 编辑角色
  ROLE_MANAGE_DELETE: 'roles.delete', // 删除角色

  // 用户管理
  USER_MANAGE_CREATE: 'users.create', // 创建用户
  USER_MANAGE_EDIT: 'users.edit', // 编辑用户
  USER_MANAGE_DELETE: 'users.delete', // 删除用户
  USER_MANAGE_ENABLE: 'users.enable', // 启用/停用用户
  USER_MANAGE_USER_ROLE: 'users.user-role', // 配置用户角色
  USER_MANAGE_SET_PWD: 'users.set-password', // 设置用户密码

  // 教育厅/局管理
  INSTITUTION_MANAGE_ENABLE: 'institutions.enable', // 教育厅/局启用/禁用
  INSTITUTION_MANAGE_CREATE: 'institutions.create', // 创建教育厅/局
  INSTITUTION_MANAGE_EDIT: 'institutions.edit', // 编辑教育厅/局
  INSTITUTION_MANAGE_CREATE_CHILDREN: 'institutions.children.create', // 创建子教育厅/局
  INSTITUTION_MANAGE_DELETE: 'institutions.delete', // 删除教育厅/局
  INSTITUTION_MANAGE_USER_LIST: 'institutions.user-list', // 教育厅/局用户列表
  INSTITUTION_MANAGE_USER_PWD: 'institutions.user-list.set-pwd', // 教育厅/局用户列表设置用户密码

  // 学校管理
  SCHOOL_MANAGE_ENABLE: 'schools.enable', // 学校启用/禁用
  SCHOOL_MANAGE_CREATE: 'schools.create', // 创建学校
  SCHOOL_MANAGE_EDIT: 'schools.edit', // 编辑学校
  SCHOOL_MANAGE_DELETE: 'schools.delete', // 删除学校
  SCHOOL_MANAGE_USER_LIST: 'schools.user-list', // 学校用户列表
  SCHOOL_MANAGE_USER_PWD: 'schools.user-list.set-pwd', // 学校用户列表设置用户密码

  // 考点管理
  EXAM_SITE_MANAGE_CREATE: 'exam-sites.create', // 考点创建
  EXAM_SITE_MANAGE_EDIT: 'exam-sites.edit', // 考点编辑
  EXAM_SITE_MANAGE_ENABLE: 'exam-sites.enable', // 考点启用
  EXAM_SITE_MANAGE_DELETE: 'exam-sites.delete', // 考点删除
  EXAM_SITE_MANAGE_EXAM_ROOM_LIST: 'exam-sites.exam-room', // 考场列表

  // 考场管理
  EXAM_ROOM_MANAGE_CREATE: 'exam-rooms.create', // 考场创建
  EXAM_ROOM_MANAGE_EDIT: 'exam-rooms.edit', // 考场编辑
  EXAM_ROOM_MANAGE_ENABLE: 'exam-rooms.enable', // 考场启用
  EXAM_ROOM_MANAGE_DELETE: 'exam-rooms.delete', // 考场删除
  EXAM_ROOM_MANAGE_EXAM_ROOM_SEATS: 'exam-rooms.seats.list', // 座位列表
  EXAM_ROOM_MANAGE_EXAM_INVIGILATORS: 'exam-rooms.invigilators.list', // 座位列表

  // 座位管理
  EXAM_SEATS_MANAGE_ENABLE: 'exam-seats.enable', // 启用座位
  EXAM_SEATS_MANAGE_CREATE: 'exam-seats.create', // 创建座位
  EXAM_SEATS_MANAGE_DELETE: 'exam-seats.delete', // 删除座位
  EXAM_SEATS_MANAGE_EDIT: 'exam-seats.edit', // 编辑座位
  EXAM_SEATS_MANAGE_BATCH_CREATE: 'exam-seats.batch-create', // 批量创建座位
  EXAM_SEATS_MANAGE_BATCH_DELETE: 'exam-seats.batch-delete', // 批量编辑座位
  EXAM_SEATS_MANAGE_BATCH_ENABLE: 'exam-seats.batch-enable', // 批量编辑座位

  // 监考主机管理
  EXAM_INVIGILATOR_MANAGE_CREATE: 'exam-invigilators.create', // 创建监考主机
  EXAM_INVIGILATOR_MANAGE_EDIT: 'exam-invigilators.edit', // 编辑监考主机
  EXAM_INVIGILATOR_MANAGE_DELETE: 'exam-invigilators.delete', // 删除监考主机
  EXAM_INVIGILATOR_MANAGE_ENABLE: 'exam-invigilators.enable', // 启用监考主机

  // 题库管理相关
  QBANK_MANAGE_PERSONAL_LIST: 'qbank-manage.personal', // 私人题库列表
  QBANK_MANAGE_PUBLIC_LIST: 'qbank-manage.public', // 公开题库列表
  QBANK_MANAGE_OFFICIAL_LIST: 'qbank-manage.official', // 官方题库列表
  // 题库管理私人题库相关
  QBANK_MANAGE_PERSONAL_CREATE: 'qbank-manage.personal.create', // 我的题库创建题目
  QBANK_MANAGE_PERSONAL_EDIT: 'qbank-manage.personal.edit', // 我的题库编辑题目
  QBANK_MANAGE_PERSONAL_DELETE: 'qbank-manage.personal.delete', // 我的题库删除题目
  QBANK_MANAGE_PERSONAL_SET_PUBLIC: 'qbank-manage.personal.set-public', // 我的题库启用/禁用题目
  QBANK_MANAGE_BATCH_SET_PUBLIC: 'qbank-manage.batch-public', // 我的题库批量设为公开
  // 题库管理官方题库相关
  QBANK_MANAGE_OFFICIAL_CREATE: 'qbank-manage.official.create', // 官方题库创建
  QBANK_MANAGE_OFFICIAL_COPY: 'qbank-manage.official.copy', // 官方题库复制
  QBANK_MANAGE_OFFICIAL_EDIT: 'qbank-manage.official.edit', // 官方题库编辑
  QBANK_MANAGE_OFFICIAL_BATCH_COPY: 'qbank-manage.official.batch-copy', // 官方题库批量复制
  QBANK_MANAGE_OFFICIAL_DELETE: 'qbank-manage.official.delete', // 官方题库删除
  QBANK_MANAGE_OFFICIAL_SET_TAG: 'qbank-manage.official.set-tags',
  // 题库管理公开题库相关
  QBANK_MANAGE_PUBLIC_COPY: 'qbank-manage.public.copy', // 复制官方题目
  QBANK_MANAGE_PUBLIC_BATCH_COPY: 'qbank-manage.public.batch-copy', // 批量复制公开题目

  // 试卷管理相关
  TEST_PAPER_PERSONAL: 'test-paper.personal',
  TEST_PAPER_OFFICIAL: 'test-paper.official',
  // 试卷管理私有试卷相关
  TEST_PAPER_PERSONAL_LIST: 'test-paper.personal.list', // 私有试卷列表
  TEST_PAPER_PERSONAL_CREATE: 'test-paper.personal.create', // 私有试卷创建
  TEST_PAPER_PERSONAL_EDIT: 'test-paper.personal.edit', // 私有试卷编辑
  TEST_PAPER_PERSONAL_DELETE: 'test-paper.personal.delete', // 私有试卷删除
  TEST_PAPER_PERSONAL_COPY: 'test-paper.personal.copy', // 私有试卷复制
  // 试卷管理官方试卷相关：
  TEST_PAPER_OFFICIAL_LIST: 'test-paper.official.list', // 官方试卷列表
  TEST_PAPER_OFFICIAL_CREATE: 'test-paper.official.create', // 官方试卷创建
  TEST_PAPER_OFFICIAL_EDIT: 'test-paper.official.edit', // 官方试卷编辑
  TEST_PAPER_OFFICIAL_DELETE: 'test-paper.official.delete', // 官方试卷删除
  TEST_PAPER_OFFICIAL_COPY: 'test-paper.official.copy', // 官方试卷复制
  // 考试管理
  EXAMS_MANAGE_CREATE: 'exams.create', // 考试创建
  EXAMS_MANAGE_LIST: 'exams.list', // 考试列表
  EXAMS_MANAGE_DETAILS: 'exams.details', // 考试详情
  EXAMS_MANAGE_EDIT: 'exams.edit', // 考试编辑
  EXAMS_MANAGE_DELETE: 'exams.delete', // 考试删除
  // 考试管理复核老师相关
  EXAMS_MANAGE_REVIEW_USERS_LIST: 'exam-review-users.list', // 复核老师列表
  EXAMS_MANAGE_REVIEW_USERS_CREATE: 'exam-review-users.create', // 复核老师添加
  EXAMS_MANAGE_REVIEW_USERS_DELETE: 'exam-review-users.delete', // 复核老师删除
  EXAMS_MANAGE_REVIEW_USERS_BATCH_CREATE: 'exam-review-user.batch.create', // 批量复核老师添加
  // 考试管理考场设置
  EXAMS_MANAGE_ROOMS_LIST: 'exams.rooms.list', // 考场列表
  EXAMS_MANAGE_ROOMS_CREATE: 'exams.rooms.create', // 考场创建
  EXAMS_MANAGE_ROOMS_BATCH_CREATE: 'exams.rooms.batch.create', // 批量考场添加
  EXAMS_MANAGE_ROOMS_DELETE: 'exams.rooms.delete', // 考场删除
  EXAMS_MANAGE_ROOMS_BATCH_DELETE: 'exam.rooms.batch-delete',
  // 考试管理场次管理
  EXAMS_MANAGE_ROUNDS_LIST: 'exams.rounds.list', // 场次列表
  EXAMS_MANAGE_ROUNDS_CREATE: 'exams.rounds.create', // 场次创建
  EXAMS_MANAGE_ROUNDS_EDIT: 'exams.rounds.edit', // 场次编辑
  EXAMS_MANAGE_ROUNDS_DELETE: 'exams.rounds.delete', // 场次删除
  EXAMS_MANAGE_ROUNDS_SET_PAPERS: 'exams.rounds.set-papers', // 场次试卷设置
  EXAMS_MANAGE_ROUNDS_SET_PAPERS_CREATE: 'exams.rounds.set-papers.create', // 场次试卷添加
  EXAMS_MANAGE_ROUNDS_SET_PAPERS_SELECT: 'exams.rounds.set-papers.select', // 场次试卷选择
  EXAMS_MANAGE_ROUNDS_SET_PAPERS_EDIT: 'exams.rounds.set-papers.edit', // 场次试卷编辑
  EXAMS_MANAGE_ROUNDS_SET_PAPERS_PREVIEW: 'exams.rounds.set-papers.preview', // 场次试卷预览
  EXAMS_MANAGE_ROUNDS_SET_PAPERS_DELETE: 'exams.rounds.set-papers.delete', // 场次试卷删除
  EXAMS_MANAGE_ROUNDS_STUDENTS: 'exams.rounds.students', // 场次考生名单
  // 报考考生相关
  EXAMS_MANAGE_STUDENTS_LIST: 'exam-students.list', // 考生列表
  EXAMS_MANAGE_STUDENTS_SET_SEAT: 'exam-students.set-seat', // 考生分配座位
  EXAMS_MANAGE_STUDENTS_CREATE: 'exam-students.create', // 考生信息录入
  EXAMS_MANAGE_STUDENTS_EDIT: 'exam-students.edit', // 考生信息编辑
  EXAMS_MANAGE_STUDENTS_DELETE: 'exam-students.delete', // 考生信息删除
  EXAMS_MANAGE_STUDENTS_BATCH_CREATE: 'exam-students.batch-create', // 考生信息批量导入
  // 阅卷老师相关
  EXAMS_MANAGE_MARKING_USERS_LIST: 'exams.marking-user.list', // 阅卷老师列表
  EXAMS_MANAGE_MARKING_USERS_CREATE: 'exams.marking-user.create', // 阅卷老师添加
  EXAMS_MANAGE_MARKING_USERS_DELETE: 'exams.marking-user.delete', // 阅卷老师删除
  EXAMS_MANAGE_MARKING_USERS_SET_USER: 'exams.marking-user.set-user', // 给题目设置阅卷老师
  EXAMS_MANAGE_MARKING_USERS_SET_PAPER: 'exams.marking-user.set-paper', // 给老师设置阅卷题目
  EXAMS_MANAGE_MARKING_USERS_BATCH_EDIT: 'exams.marking-user.batch-edit', // 批量编辑阅卷老师信息
  // 考试管理加密试卷包相关
  EXAMS_MANAGE_PAPER_PACKAGE_LIST: 'exam-paper-package.list', // 加密试卷包列表
  EXAMS_MANAGE_PAPER_PACKAGE_CREATE: 'exam-paper-package.create', // 加密试卷包生成
  EXAMS_MANAGE_PAPER_PACKAGE_DOWNLOAD: 'exam-paper-package.download', // 加密试卷包下载
  EXAMS_MANAGE_PAPER_PACKAGE_SHOW_HISTORY: 'exam-paper-package.show-history', // 加密试卷包查看生成历史
  // 考场每日编排数据包相关
  EXAMS_MANAGE_ARRANGE_PACKAGE_LIST: 'exams.arrange-package.list', // 考场编排数据包列表
  EXAMS_MANAGE_ARRANGE_PACKAGE_DETAIL: 'exams.arrange-package.detail', // 考场编排数据包详情
  EXAMS_MANAGE_ARRANGE_PACKAGE_CREATE: 'exams.arrange-package.create', // 考场编排数据包生成
  EXAMS_MANAGE_ARRANGE_PACKAGE_HISTORY: 'exams.arrange-package.history', // 考场编排数据包历史
  EXAMS_MANAGE_ARRANGE_PACKAGE_DOWNLOAD: 'exams.arrange-package.download', // 考场编排数据包详情

  // 考场统计相关
  EXAMS_ROOMS_SUMMARY_LIST: 'exams.rooms-summary.list', // 考场统计列表
  EXAMS_ROOMS_SUMMARY_DOWNLOAD: 'exams.rooms-summary.download', // 导出考场编排数据包
  EXAMS_ROOMS_SUMMARY_BATCH_UPLOAD: 'exams.rooms-summary.batch-upload', // 批量导入编排
  EXAMS_ROOMS_SUMMARY_DISTRIBUTE_SEAT: 'exams.rooms-summary.distribute', // 批量设置考试座位

  // 免考考生相关
  EXAMS_MANAGE_EXEMPT_STUDENT_LIST: 'exams.exempt-stu.list', // 免考考生列表
  EXAMS_MANAGE_EXEMPT_STUDENT_CREATE: 'exams.exempt-stu.create', // 新增免考考生
  EXAMS_MANAGE_EXEMPT_STUDENT_DELETE: 'exams.exempt-stu.delete', // 删除免考考生
  EXAMS_MANAGE_EXEMPT_STUDENT_LOGS: 'exams.exempt-stu.logs', // 免考考生日志

  // 作弊考生相关
  EXAMS_MANAGE_CHEAT_STUDENT_LIST: 'exams.cheat-student.list', // 作弊考生列表
  EXAMS_MANAGE_CHEAT_STUDENT_SET: 'exams.cheat-student.set', // 作弊考生设置
  EXAMS_MANAGE_CHEAT_STUDENT_LOGS: 'exams.cheat-student.logs', // 作弊考生日志

  // 阅卷管理相关
  MARKING_MANAGE_MARKING_PAPER_LIST: 'marking.marking-paper.list', // 阅卷列表
  MARKING_MANAGE_MARKING_PAPER_MARK: 'marking.marking-paper.mark', // 评阅试卷
  // 阅卷管理答卷包相关
  MARKING_MANAGE_ANSWER_PACKAGES_IMPORT: 'marking.answer-packages.import', // 答卷包上传
  MARKING_MANAGE_ANSWER_PACKAGES_HISTORY: 'marking.answer-packages.history', // 答卷包导入历史

  // 阅卷管理阅卷复核三改卷相关
  MARKING_MANAGE_THREE_REVIEW_LIST: 'marking.three-review.list', // 三改卷复核列表
  MARKING_MANAGE_THREE_REVIEW_REVIEW: 'marking.three-review.review', // 三改卷复核

  // 阅卷管理阅卷复核重大分歧相关
  MARKING_MANAGE_REVIEW_LIST: 'marking.review.list', // 重大分歧复核列表
  MARKING_MANAGE_REVIEW_REVIEW: 'marking.review.review', // 重大分歧复核

  // 成绩查看
  EXAMS_MANAGE_PERFORMANCE_VIEW: 'exams.performance.view', // 学生成绩查看
  EXAMS_MANAGE_PERFORMANCE_EXPORT: 'exams.performance.export', // 成绩导出
  EXAMS_MANAGE_PERFORMANCE_WEB_VIEW: 'exams.performance.web-view', // WEB版成绩查看
  EXAMS_MANAGE_PERFORMANCE_H5_VIEW: 'exams.performance.h5-view', // 移动版成绩查看

  // 数据统计指标
  EXAMS_MANAGE_STAT: 'exams.stat',
};
