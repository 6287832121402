import { stringify } from 'qs';
import { request } from 'umi';

// 获取当前用户的信息
export async function queryCurrent() {
  return request('/backend/profile/info');
}

// 获取用户的菜单信息
export async function queryMenuInfo() {
  return request('/backend/profile/menus');
}

// 获取用户权限列表
export async function queryPermissions() {
  return request('/backend/profile/permissions');
}

export async function queryRegionMap() {
  return request('/backend/common/regions');
}

// 操作编码映射
export const getOprCodeMap = (params = {}) =>
  request(`/backend/backend-user-opr-log/opr-code-maps`, {
    method: 'GET',
    params,
  });

export const retryRequest = (url: string, headers: any, method: any, params: any) => {
  // 判断是否是get方法，如果是需要用params去配置请求参数
  if (method === 'GET') {
    return request(url, {
      method,
      headers,
      params,
      paramsSerializer: (paramsObj) => stringify(paramsObj, { arrayFormat: 'brackets' }),
    });
  }
  // POST,DELETE等请求用data字段进行配置
  return request(url, {
    method,
    headers,
    data: params,
  });
};
