export default [
  {
    path: '/',
    redirect: '/home',
  },
  {
    layout: false,
    path: '/access/:loginType/login',
    component: './access/login',
  },
  {
    component: './404',
  },
];
