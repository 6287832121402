import type {
  BasicLayoutProps,
  MenuDataItem,
  Settings as LayoutSettings,
} from '@ant-design/pro-layout';
import { PageLoading } from '@ant-design/pro-layout';
import { notification } from 'antd';
import type { RequestConfig } from 'umi';
import path from 'path';
import pathToRegexp from 'path-to-regexp';
import { history } from 'umi';
import RightContent from '@/components/RightContent';
import Footer from '@/components/Footer';
import type { ResponseError } from 'umi-request';
import { run } from '@/core';
import { getCookieToken, requestMiddleware, accessInterceptors } from './utils/request';
import { queryCurrent, queryMenuInfo, queryPermissions } from './utils/common';
import { list2tree } from '@/utils/tree';
import defaultSettings from '@ant-design/pro-layout/lib/defaultSettings';
import * as IconMap from '@ant-design/icons';
import logoStatic from '@/pages/access/static/login_logo.png';
import rawRouter from '../config/routes';

/** 获取用户信息比较慢的时候会展示一个 loading */
export const initialStateConfig = {
  loading: <PageLoading />,
};

const flattenRouter = (r: any): any[] =>
  r.reduce(
    (acc: any[], val: any) =>
      Array.isArray(val?.routes)
        ? acc.concat([val]).concat(
            flattenRouter(
              val.routes.map((_r: any) => ({
                ..._r,
                path: _r.path[0] === '/' ? _r.path : path.join(val.path, _r.path),
              })),
            ),
          )
        : acc.concat([val]),
    [],
  );

const routerMap = flattenRouter(rawRouter).reduce(
  (acc: any, val: any) => (val.name ? { [val.path]: val.name, ...acc } : acc),
  {},
);

// 面包屑处理
const breadcrumbRender = (): any[] => {
  const breadcrumb: { breadcrumbName: string; path?: string }[] = [
    { breadcrumbName: '首页', path: '/home' },
  ];
  const currentPath = window.location.pathname;
  const paths = currentPath.split('/').filter((p) => p.trim() !== '');
  for (let i = 0; i < paths.length; i += 1) {
    const p = `/${paths.slice(0, i + 1).join('/')}`;
    if (routerMap[p]) breadcrumb.push({ breadcrumbName: routerMap[p], path: p });
    else {
      // FIX: pathToRegexp 报错 不知道为什么
      const pathKey = Object.keys(routerMap).find((key) => pathToRegexp(key).test(p));
      if (pathKey) breadcrumb.push({ breadcrumbName: routerMap[pathKey], path: p });
    }
  }
  return breadcrumb;
};

// 将返回的菜单数据中的icon变为标签
const loopMenuItem = (menus: MenuDataItem[]): MenuDataItem[] =>
  menus.map(({ icon, children, ...item }) => ({
    ...item,
    icon: icon && IconMap[icon as string]?.render(),
    children: children && loopMenuItem(children),
  }));

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentUser?: any;
  core: ReturnType<typeof run>;
  menuData?: any[];
  fetchAll?: any;
  permission: string[];
}> {
  // 获取用户信息
  const fetchUserInfo = async () => {
    try {
      const currentUser = await queryCurrent();
      return currentUser;
    } catch (error) {
      // history.push('/user/login');
      // 默认重定向到教育厅/局登录页面
      window.location.href = '/access/institution/login';
    }
    return undefined;
  };

  // 获取用户的菜单信息
  const fetchMenuInfo = async () => {
    try {
      const menuInfo = await queryMenuInfo();
      // 第一个根目录的不需要显示出来
      return menuInfo;
    } catch (error) {
      return [];
    }
  };

  // 获取用户的权限信息
  const fetchPermissions = async () => {
    try {
      const permissions = await queryPermissions();
      return permissions;
    } catch (error) {
      return [];
    }
  };

  // 统一执行获取信息的方法
  const fetchAll = async () => {
    try {
      const currentUser = await fetchUserInfo();
      const menuInfo = await fetchMenuInfo();
      let permission = await fetchPermissions();
      const menuData = list2tree(
        menuInfo.sort((a: any, b: any) => a?.sort_order - b?.sort_order),
        { rootId: 1 },
      );
      if (permission.length === undefined || permission.length < 0) {
        permission = [];
      }

      if (menuInfo.length > 0) {
        permission.push(...menuInfo.map((m: any) => m.permission_name));
      }
      const core = run({ permission });
      return { currentUser, menuData, permission, core };
    } catch (error) {
      window.location.href = '/access/institution/login';
    }
    return undefined;
  };

  // 如果是登录页面，不执行
  if (
    history.location.pathname === '/access/institution/login' ||
    history.location.pathname === '/access/school/login' ||
    history.location.pathname === '/access/starnet-system/login'
  ) {
    try {
      if (getCookieToken()) {
        const currentUser = await queryCurrent();
        if (currentUser) {
          window.location.href = '/home';
        } else {
          return {
            fetchAll,
            core: run({ permission: [] }),
            settings: defaultSettings,
            permission: [],
            currentUser: {},
            menuData: [],
          };
        }
      } else {
        return {
          fetchAll,
          core: run({ permission: [] }),
          settings: defaultSettings,
          permission: [],
          currentUser: {},
          menuData: [],
        };
      }
    } catch (error) {
      console.log(error.message);
      return {
        fetchAll,
        core: run({ permission: [] }),
        settings: defaultSettings,
        permission: [],
        currentUser: {},
        menuData: [],
      };
    }
  }

  try {
    // 获取信息
    // eslint-disable-next-line prefer-const
    let [currentUser, menuInfo, currentPermission] = await Promise.all([
      queryCurrent(),
      queryMenuInfo(),
      queryPermissions(),
    ]);
    if (currentPermission.length === undefined || currentPermission.length < 0) {
      currentPermission = [];
    }
    if (menuInfo.length > 0) {
      // 菜单权限加入权限列表里
      currentPermission.push(...menuInfo.map((m: any) => m.permission_name));
    }
    const core = run({
      permission: currentPermission,
    });

    const menuData = list2tree(
      menuInfo.sort((a: any, b: any) => a?.sort_order - b?.sort_order),
      { rootId: 1 },
    );
    return {
      fetchAll,
      core,
      currentUser,
      settings: defaultSettings,
      permission: currentPermission,
      menuData,
    };
  } catch (error) {
    console.log(error);
    window.location.href = '/access/institution/login';
    return {
      fetchAll,
      core: run({ permission: [] }),
      currentUser: {},
      settings: defaultSettings,
      permission: [],
      menuData: [],
    };
  }
}

export const layout = ({
  initialState,
}: {
  initialState: {
    settings?: LayoutSettings;
    menuData: any[];
    currentUser?: any;
  };
}): BasicLayoutProps => {
  return {
    ...initialState?.settings,
    rightContentRender: () => <RightContent />,
    disableContentMargin: false,
    footerRender: () => <Footer />,
    onPageChange: () => {
      const { location } = history;
      // 如果没有登录，重定向到 login
      if (
        !initialState?.currentUser &&
        location.pathname !== '/access/institution/login' &&
        location.pathname !== '/access/starnet-system/login' &&
        location.pathname !== '/access/school/login'
      ) {
        history.push('/access/institution/login');
      }
    },
    menu: {
      locale: false,
    },
    // 自定义菜单标题，修改了字体大小
    menuHeaderRender: (logo, title) => {
      return (
        <div
          id="customize_menu_header"
          onClick={() => {
            history.push('/home');
          }}
        >
          {logo}
          <strong style={{ color: 'white', fontSize: 15, paddingLeft: 10 }}>
            {(title as any)?.props?.children}
          </strong>
        </div>
      );
    },
    formatMessage: (m) => m.defaultMessage || '',
    menuDataRender: () => loopMenuItem(initialState?.menuData ? initialState.menuData : []),
    // 自定义 403 页面
    // unAccessible: <div>unAccessible</div>,
    pageTitleRender: false,
    breadcrumbRender,
    title: '音乐考试管理平台',
    logo: logoStatic,
    fixedHeader: true,
    fixSiderbar: true,
  };
};

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  405: '请求方法不被允许。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

/**
 * 异常处理程序
 */
const errorHandler = async (error: ResponseError) => {
  const { response } = error;
  if (response && response.status) {
    const json = await response.json();
    const msg = json?.errormessage || codeMessage[response.status];

    const { location } = history;
    if (
      response.status === 401 &&
      location.pathname !== '/access/institution/login' &&
      location.pathname !== '/access/starnet-system/login' &&
      location.pathname !== '/access/school/login'
    ) {
      history.push('/access/institution/login');
    }

    if (
      response.url !== `${process.env.APP_API_URL}/backend/auth/token/refresh` &&
      response.status !== 401
    ) {
      notification.error({
        message: msg,
      });
    }
  }
  throw error;
};

// https://umijs.org/zh-CN/plugins/plugin-request
export const request: RequestConfig = {
  errorHandler,
  errorConfig: {
    adaptor(data: { ok: any; message: any }) {
      return data ? { ...data, success: true, errorMessage: data.message } : { success: false };
    },
  },
  middlewares: [requestMiddleware],
  responseInterceptors: [accessInterceptors],
};
