import { Settings as LayoutSettings } from '@ant-design/pro-layout';

export default {
  navTheme: 'light',
  primaryColor: '#1ec38f',
  layout: 'mix',
  contentWidth: 'Fluid',
  fixedHeader: true,
  fixSiderbar: true,
  colorWeak: false,
  title: '音乐考试管理平台',
  menu: {
    locale: false,
  },
  pwa: false,
  logo: '',
  iconfontUrl: '',
} as LayoutSettings & {
  pwa: boolean;
};
