import answerPackages from './answer-packages';
import markingPaper from './marking-paper';
import markingReview from './marking-review';

export default {
  path: '/marking',
  name: '阅卷管理',
  routes: [
    answerPackages,
    markingReview,
    markingPaper,
    {
      path: './',
      redirect: './marking-paper',
    },
  ],
};
