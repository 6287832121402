import qbankManage from './qbank-manage';
import testPaper from './test-paper';

export default {
  path: '/proposition',
  icon: 'UnorderedListOutlined',
  routes: [
    qbankManage,
    testPaper,
    {
      path: './',
      redirect: './qbank-manage',
    },
  ],
};
