import { queryToken } from './service';
import { setCookieToken, getCookieRefreshToken } from '../request';

/**
 * 这个是刷新token的单例工具类
 * 主要功能： 进行token的刷新，考虑到有可能存在多个请求在短时间内发出，
 * 因此使用单例的形式，所有的请求在接收到401时都会在请求后拦截器中调用这个工具类刷新token，
 * 刷新token方法只会返回一个promise对象，当新的 token已经设置好后，
 * 会将waitList方法中所有保存的resolve方法都执行，通知所有阻塞着等待的接口请求
 * 若刷新失败，会调用等待队列里的所有reject方法通知阻塞的接口
 */
export class RefreshTokenSingle {
  // 单例
  private static ins: RefreshTokenSingle | null = null;
  // 等待队列
  private waitList: any[] = [];
  // 是否已经开始进行token的刷新了
  private loading: boolean = false;

  private constructor() {}

  // 获取单例对象
  public static getInstance(): RefreshTokenSingle {
    if (!RefreshTokenSingle.ins) {
      RefreshTokenSingle.ins = new RefreshTokenSingle();
    }
    return RefreshTokenSingle.ins;
  }

  private getLoading() {
    return this.loading;
  }

  // 通知成功
  private notifyAllWaitSuccess() {
    this.waitList.forEach((item: { resolve: any; reject: any }) => {
      item.resolve();
    });
    this.waitList = [];
    this.loading = false;
  }

  // 通知失败
  public notifyAllError() {
    this.waitList.forEach((item) => {
      item.reject(new Error('更新token失败'));
    });
    this.waitList = [];
    this.loading = false;
  }

  // 请求接口刷新token
  public requestToRefreshToken() {
    const refreshTokenData = getCookieRefreshToken();
    queryToken({ refresh_token: refreshTokenData })
      .then((data) => {
        const { token, refresh_token } = data as any;
        // 保存新的token
        setCookieToken(token, refresh_token);
        this.notifyAllWaitSuccess();
      })
      .catch((error) => {
        console.log(error);
        this.notifyAllError();
      });
  }

  // 对外暴露的更新token的方法
  public refreshToken() {
    if (this.getLoading() === false) {
      this.requestToRefreshToken();
      this.loading = true;
    }
    return new Promise((resolve, reject) => {
      this.waitList.push({ resolve, reject });
    });
  }
}
