export default {
  path: './marking-paper',
  name: '评阅试卷',
  routes: [
    {
      path: './',
      redirect: './exam-list',
    },
    {
      path: './exam-list',
      component: './marking-management/marking-paper',
    },
    {
      path: './:examId/paper',
      name: '评阅',
      component: './marking-management/marking-paper/components/MarkingPaperForm',
    },
  ],
};
