export default {
  path: './test-paper',
  name: '试卷管理',
  routes: [
    {
      path: './',
      name: '试卷管理',
      component: './proposition/test-paper/index',
    },
    {
      path: './create',
      name: '新建试卷',
      component: './proposition/test-paper/create',
    },
    {
      path: './edit/:paperId',
      name: '编辑试卷',
      component: './proposition/test-paper/edit',
    },
  ],
};
