import { querySitesList } from './sites/service';
import { queryExamRoom } from './rooms/service';

export const namespace = 'siteManagement';

export const effects = {
  getCurrentSite: `${namespace}/getCurrentSite`,
  setCurrentSite: `${namespace}/setCurrentSite`,
  getCurrentRoom: `${namespace}/getCurrentRoom`,
  setCurrentRoom: `${namespace}/setCurrentRoom`,
};

export default {
  namespace,

  state: {
    data: {},
  },

  effects: {
    *getCurrentSite({ payload }, { call, put }) {
      const data = yield call(querySitesList);
      const values = data.items.filter((item) => {
        return item.id === +payload.id;
      });
      yield put({
        type: 'saveData',
        payload: values,
      });
    },

    *setCurrentSite({ payload }, { put }) {
      yield put({
        type: 'saveData',
        payload,
      });
    },
    *getCurrentRoom({ payload }, { call, put }) {
      const data = yield call(queryExamRoom);
      const values = data.items.filter((item) => {
        return item.id === +payload.id;
      });
      yield put({
        type: 'saveData',
        payload: values,
      });
    },

    *setCurrentRoom({ payload }, { put }) {
      yield put({
        type: 'saveData',
        payload,
      });
    },
  },

  reducers: {
    saveData(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
  },
};
