export default {
  path: './:exam_id/rooms-summary/',
  name: '考场情况',
  routes: [
    {
      path: './',
      component: './exams/rooms-summary',
    },
    {
      path: './distribute',
      name: '自动分配',
      component: './exams/distribute',
    },
  ],
};
