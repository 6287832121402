import React from 'react';

let localPermission: string[] = [];


export function initPermissions(data: string[]) {
  localPermission = data;
}

export function getPermissionList() {
  return localPermission || [];
}

/**
 * 检查props是否在权限中
 * @param {*} props
 * @return boolean
 */
const checkPropsPermission = (props: any) => {
  const { permission } = props;
  if (permission === undefined)
    return console.warn(
      `permission component need a permission as props,If you don't need it at any time, set it to null，or remove the component`,
    );
  if (permission === null) return false;
  if (Array.isArray(permission)) {
    return permission.every((item) => getPermissionList().includes(item));
  }

  if (typeof permission === 'string') {
    if (getPermissionList().indexOf(permission) >= 0) {
      return true;
    }
  }
  return false;
};

/**
 * 普通无状态组件,包裹在普通组件外层
 * @param { props } props
 * @example
 *  <AuthorityWrapper permission="search"> <Element></Element> </AuthorityWrapper>
 *  <AuthorityWrapper permission={['search','edit']}> <Element></Element> </AuthorityWrapper>
 */
export const AuthorityWrapper = (props: any) => {
  if (checkPropsPermission(props)) {
    const childrenProps = Object.entries(props)
      .filter(([key]) => key !== 'children')
      .reduce((acc, [key, val]) => {
        // 将 wrapper 元素的上的 属性透传到 下面的子元素
        // 如: <AuthorityWrapper disable={true} permission="search"> <Input /></AuthorityWrapper>
        // 通过下面的判断 可以将 disable 透传给 Input， 但是不推荐这么做，此处是为了处理外部框架的行为
        if (!(typeof props.children.props === 'object' && !props.children.props[key])) return acc;
        return { ...acc, [key]: val };
      });
    return React.Children.map(props.children, (children) =>
      React.cloneElement(children, childrenProps),
    );
  }
  return null;
};

/**
 * 权限操作高阶组件
 * @param { 需要封装的权限组件 } React.Component
 * @return { 返回的高阶组件继承了原组件 在返回的高阶组件的props上添加 permission，控制组件的显示 }
 * @example
 * AuthButton = AuthorityHOC(Button)
 * AuthMenuItem = AuthorityHOC(Menu.Item)
 * <AuthButton permission="search"></AuthButton>
 * <AuthMenuItem permission={['search','edit']}></AuthMenuItem>
 */
export const withAuthority = (WrappedComponent: (props: any) => JSX.Element) => {
  return ({ children, ...props }: any) =>
    checkPropsPermission(props) && <WrappedComponent {...props}>{children}</WrappedComponent>;
};

/**
 * 过滤权限
 * @param {需要过滤的列表} list
 * @example
 * const tabList = [{{key: 'info',tab: '场所详情',permission: 'search',}]
 * const AuthTabList = filterPermission(tabList)
 */
export const filterPermission = (list: any) => {
  if (!Array.isArray(list)) return [];
  return list.filter((item) => {
    if (Array.isArray(item.permission)) {
      return getPermissionList().some((permission) => item.permission.indexOf(permission) >= 0);
    }
    if (item.permission === undefined) return true;
    return getPermissionList().indexOf(item.permission) >= 0;
  });
};
